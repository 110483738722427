.quicklook-container {
    margin-top: 130px;
}

.quicklook-inner-container {
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;

    @media (max-width: 768px) {
        padding: 0px;
    }
}

.quicklook-card {
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    /* Smoother transition for movement and shadow */
    position: relative;
    width: 33%;
    overflow: hidden;

    .product-image {
        transform: scale(1.0);
        width: 100%;
        height: auto;
        aspect-ratio: 1 / 1;
        object-fit: contain;
        cursor: pointer;
    }
}

.quicklook-card:hover {
    transform: translateY(-10px);
    /* Smooth movement upwards */
    // box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2); /* Add a slight shadow effect for depth */
}

.quicklook-heading-content {
    width: 50%;
    text-align: center;
    padding: 20px;

    @media (max-width: 768px) {
        width: 100%;
        margin-bottom: 10px;
    }
}

.quicklook-product-container {
    width: 60%;
    display: flex;
    flex-direction: column;
    align-items: center;

    span {
        margin: 20px;
        display: block !important;
    }

    @media (max-width: 768px) {
        width: 100%;
    }
}

.quicklook-category-container {
    margin-bottom: 20px;
    text-align: center;
}

.quicklook-product-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px 0px,
}

.quicklook-description {
    display: block;
}

.quicklook-divider {
    border-top: 1px solid #c9c9c9;
    width: 70%;
}

.quicklook-loading-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 500px;
    font-size: x-large;
}

.examples-on-web-container {
    width: 60%;
    margin: 40px 0px;

    @media (max-width: 768px) {
        width: 100%;
        padding: 6px;

        h1 {
            font-size: 16px !important;
        }

        p {
            font-size: 12px !important;
        }
    }
}

.examples-on-web-card {
    width: 100%;
    height: 340px;
    display: flex;
    border: 1px solid #c9c9c9;
    overflow: hidden;
    position: relative;
    border-radius: 20px;
    justify-content: flex-end;
    align-items: center;
}

.cubePhoneImgs {
    position: absolute;
    width: 26%;
    margin: 30px;
    left: 10px;
    top: 10px;
}

.cube-info-text {
    width: 50%;
    margin: 10px;
}

.sqa-card {
    margin-top: 40px;
    width: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    @media (max-width: 768px) {
        flex-direction: column;
        text-align: center;
    }
}

.sqa-info-text {
    text-align: center;
    padding: 10px;

    h1 {
        display: block !important;
    }
}

.sqaPhoneImg {
    width: 50%;

    @media (max-width: 768px) {
        width: 80%;
    }
}

.product-card-wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    text-align: center;
    height: 100%;
    min-height: 250px;
  }

.qr-code-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
    height: 250px;
}

.icon-group {
    display: flex;
    gap: 10px;
    justify-content: center;
    position: absolute;
    bottom: -30px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 5;
}

.icon-button {
    width: 40px;
    height: 40px;
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.08);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    cursor: pointer;
    transition: all 0.2s ease;
}

.icon-button:hover {
    box-shadow: 0 6px 16px rgba(0, 0, 0, 0.15);
    transform: scale(1.05);
}

/* Hide actions on mobile */
@media (max-width: 768px) {
    .icon-group {
        display: none;
    }
}

.hover-icon {
    width: 48px;
    height: 48px;
    background: white;
    border-radius: 12px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.06);
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.3s ease-in-out;
    cursor: pointer;

    &:hover {
        border: 1px solid #d9d9d9;
        box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
    }
}